import { QueryStringVM } from '../models/QueryStringVM';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OkService } from '../services/ok.service';
import { environment } from '../../environments/environment.test';


@Component({
  selector: 'app-ok',
  templateUrl: './ok.component.html',
  styleUrls: ['./ok.component.scss']
})
export class OkComponent implements OnInit {

  uniqueid: string = "";
  success: boolean;
  others: QueryStringVM[] = []
  urlPixel: string = "";
  pathConfig: string = environment.PATH_CONFIG;


  constructor(
    private _router: ActivatedRoute,
    private _okService: OkService
  ) {
    this._router.queryParams.subscribe(params => {

      this.success = params['success'] ? params['success'].toLocaleLowerCase() == 'false' ? false : true : false;

      if (this.success) {


      }

      let entries = Object.entries(params);

      var querystringparams = entries.map(xx => { return { ...xx } })
      this.others = querystringparams.filter(cc => cc && cc['0'] && cc['1']).map(xx => {
        return {
          key: xx['0'],
          value: xx['1']
        } as QueryStringVM
      });

      //get config
      this._okService.getConfig(this.pathConfig).then((resp) => {
        //get url pixel
        this.urlPixel = resp.url;
        if (resp && resp.pixel && resp.pixel.length > 0) {
          //for each placeholder
          resp.pixel.forEach((xx) => {
            this.urlPixel = this.urlPixel.replace(xx.placeholder, this.others.find(yy => yy.key === xx.value).value);
          })
        }
      });

    });
  }

  ngOnInit() {

  }

}
