import { IEntityBase, EntityBase } from './EntityBase';
import { IProfessioniDTO } from './ProfessioniDTO';

export interface IProvinceDTO extends IEntityBase {
    idPk: number;
    nome: string;
    sigla: string;
    idRegione: number;
    idNazione: number;
    capoluogoRegione: string;
}

export class ProvinceDTO extends EntityBase implements IProvinceDTO {
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
    }

    public idPk: number;
    public nome: string;
    public sigla: string;
    public idRegione: number;
    public idNazione: number;
    public capoluogoRegione: string;
}