import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[CharValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => CharValidator), multi: true,
    }
  ]
})
export class CharValidator implements Validator {
  constructor() { }

  validate(c: AbstractControl): { [key: string]: any } {
    return new Promise(resolve => {
      if (hasNumbers(c.value) || !String(c.value).match(/^[a-zA-Z ]*$/gm))
      resolve({ "invalidChar": true })
      else
      resolve(null)
    });

    function hasNumbers(t) {
      return /\d/.test(t);
    }
  }

}
