import { OutcomeVM } from './OutcomeVM';
import { QueryStringVM } from './QueryStringVM';

export interface IResponseVM {
    success: boolean;
    outcome: OutcomeVM;
    uniqueId: string;
    queryString: string;
    queryStringList: QueryStringVM[];
    amountProfessione: number;
}

export class ResponseVM implements IResponseVM {
    public success: boolean = true;
    public outcome: OutcomeVM = new OutcomeVM();
    public uniqueId: string;
    public queryString: string;
    public queryStringList: QueryStringVM[] = [];
    public amountProfessione: number;
}
