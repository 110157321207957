import { EntityBase, IEntityBase } from './EntityBase';

export interface IProfessioniDTO extends IEntityBase {
  idPk: number;
  testo: string;
  altDescription: string;
  isDesktopVisible: boolean;
  isMobileVisible: boolean;
}



export class ProfessioniDTO extends EntityBase implements IProfessioniDTO {

  constructor(values: Object = {}) {
    super();
    Object.assign(this, values);
  }

  public idPk: number;
  public testo: string;
  public altDescription: string;
  public isDesktopVisible: boolean;
  public isMobileVisible: boolean;
}


