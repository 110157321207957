import { IEntityBase, EntityBase } from './EntityBase';

export interface IOutcomeVM{
    statusCode: string;
    statusMessage: string;
}

export class OutcomeVM implements IOutcomeVM{
    public statusCode: string;
    public statusMessage: string;
}
