import { IEntityBase, EntityBase } from './EntityBase';
export interface IConfigVM extends IEntityBase {
    idCampagna: string;
    nomeCampagna: string;
    querystring: string[];
    typeCampagna: string;
    infoCampagna: string[];
    removeProfession: string[];
    label: string;
    colorTop: string;
    colorText: string;
    text: string;
    idPkProfessione: number;
    googleParams: boolean;
    googleParamsList: string[];
}

export class ConfigVM extends EntityBase implements IConfigVM {
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
    }

    public idCampagna: string;
    public nomeCampagna: string;
    public querystring: string[] = [];
    public typeCampagna: string;
    public infoCampagna: string[] = [];
    public removeProfession: string[] = [];
    public label: string;
    public colorTop: string;
    public colorText: string;
    public text: string;
    public idPkProfessione: number = 0;
    public googleParams: boolean = false;
    public googleParamsList: string[] = [];
}
