import { Directive, forwardRef } from '@angular/core';
import { NG_ASYNC_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { LeadService } from '../services/lead.service';

@Directive({
  selector: '[TelValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
       useExisting: forwardRef(() => TelValidator),
       multi: true,
    }
  ]
})
export class TelValidator implements Validator {
  constructor(private _leadServide: LeadService) { }

  validate(c: AbstractControl): { [key: string]: any } {
    let v: string = c.value;

    return new Promise(resolve =>
      this._leadServide.CheckPrefisso('https://mkt-webhook.signorprestito.com/api/', v).subscribe(res => {

        switch (res.data) {
          case 0:
            resolve({ 'invalidNumber': true });
          case 1:
            resolve(null);
          case 2:
            resolve(null);
          default:
            resolve(null);
        }
      }));

  }

}
