
import { Component } from '@angular/core';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor() {


    if (typeof ga === 'function') {


      ga('send', 'pageview');
    }
}}
