import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LandingDTO, ConfigVM, IpInfoVM, IntRes, ProfessioniDTO, ProvinceDTO } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private http: HttpClient) { }


  public async GetProfessioni(apiUrl: string): Promise<Array<ProfessioniDTO>> {
    return this.http.get<Array<ProfessioniDTO>>(apiUrl + `Professioni`).toPromise();
  }


  public async CheckPrefisso(apiUrl: string, prefisso: string): Promise<number> {
    return this.http.get<number>(apiUrl + `Prefissi/${prefisso}`).toPromise();
  }


  public async GetProvince(apiUrl: string): Promise<Array<ProvinceDTO>> {
    return this.http.get<Array<ProvinceDTO>>(apiUrl + `Province`).toPromise();
  }


  public async SaveLanding(apiUrl: string,landing: LandingDTO): Promise<IntRes> {
    return this.http.post<IntRes>(apiUrl + `Landing`, landing).toPromise();
  }


  public async GetAmount(apiUrl: string,idProfession: number): Promise<number> {
    return this.http.get<number>(apiUrl + `Professioni/${idProfession}/Amount`).toPromise();
  }


  public getConfig(pathConfig: string): Promise<ConfigVM> {
    return this.http.get<ConfigVM>(pathConfig).toPromise();
  }

  public getIpAddress(): Promise<IpInfoVM> {
    return this.http.get<IpInfoVM>('https://freegeoip.net/json/?callback').toPromise();
  }

}
