import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ResponseVM } from '../models/ResponseVM';
import { Router } from '@angular/router';
import { stringify, parse } from 'query-string';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { SaveLandingVM } from '../models/SaveLandingVM';
import { FormService } from '../services/form.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  env = environment.API_URL;
  winLocationSearch: string = "";
  offset: number = 50;

  constructor(
    private _router: Router,
    private _scrollToService: ScrollToService,
    private _formService: FormService
  ) { }
  /**
   * Process response
   *
   * @param {SaveLandingVM} resp
   * @memberof AppComponent
   */
  responseLanding(resp: ResponseVM) {
    let paramsObj = parse(resp.queryString);
    paramsObj.uniqueid = resp.uniqueId;
    paramsObj.success = resp.success;
    paramsObj.amount = resp.amountProfessione;

    if (resp.success) this._router.navigate(["/ok"], { queryParams: paramsObj });
  }

  ngOnInit() {
    this.winLocationSearch = window.location.search;
  }

  goToForm() {
    const config: ScrollToConfigOptions = {
      target: "header",
      offset: -20
    };

    this._scrollToService.scrollTo(config);
  }

}
