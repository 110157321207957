import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { FormService } from './services/form.service';
import { ProfessioniDTO, ProvinceDTO, LandingDTO, ResponseVM, QueryStringVM, ConfigVM } from './models';
import { AMOUNTS } from './constants/amounts';
import { parse } from "query-string";
import { createNumberMask } from 'text-mask-addons';
import { FormGroup, FormControl, NgForm } from '@angular/forms';

@Component({
  selector: 'sp-form-sito',
  templateUrl: './form-sito.component.html',
  styleUrls: ['./form-sito.component.scss']
})
export class FormSitoComponent implements OnInit, OnChanges {
  @Input() badgeShape: string;
  @Input() pathConfig: string;
  @Input() getIp: boolean = true;
  @Input() apiUrl: string = "https://www.signorprestito.it/api/";
  @Input() winLocationSearch: string = "";

  @Output() landingSaved: EventEmitter<ResponseVM> = new EventEmitter<ResponseVM>();
  @ViewChild('f', {static: true}) myForm: NgForm;

  actualStep: number = 1;
  professionsList: ProfessioniDTO[] = [];
  filteredProvince: ProvinceDTO[] = [];
  amountsList: number[] = AMOUNTS.LIST;
  landing: LandingDTO = new LandingDTO();
  errorPolicy: boolean = false;
  selectedProvince: string = "";
  myControl = new FormControl();
  myGroup: FormGroup;
  config: ConfigVM = new ConfigVM();
  errorStep1: boolean;
  errorStep2: boolean;
  errorPrefisso: boolean;
  isSending: boolean;

  private _params: any;
  private _paramsOriginal: any;
  private _qs: string = "";
  private _qsList: QueryStringVM[] = [];
  private _response: ResponseVM = new ResponseVM();

  currencyMask = createNumberMask({
    prefix: '',
    suffix: ' €',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: false
  });

  numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '',
    allowDecimal: false,
    allowLeadingZeroes: true
  });

  constructor(
    private _formService: FormService
  ) {
    this.errorStep1 = false;
    this.errorStep2 = false;
    this.errorPrefisso = false;
    this.isSending = false;
    this.landing.idProvincia = "";
  }

  ngOnInit() {

    //get list province
    this._formService.GetProvince(this.apiUrl).then((resp) => {
      this.filteredProvince = resp.filter(xx => xx.idPk !== 0);
    });
    this._params = parse(this.winLocationSearch.toLowerCase());
    this._paramsOriginal = sessionStorage.getItem('currentUrl') ? parse(sessionStorage.getItem('currentUrl')) : this.winLocationSearch;
    this._paramsOriginal = this.KeysToLower(this._paramsOriginal);

    //remove stroke from svg
    var svg = document.getElementsByClassName("mat-checkbox-checkmark-path");
    svg[0].removeAttribute("stroke");
  }

  ngOnChanges(changes: SimpleChanges) {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes && changes.pathConfig && changes.pathConfig.previousValue !== changes.pathConfig.currentValue) {
      this._formService.getConfig(this.pathConfig).then((resp) => {
        this.config = resp;

        //get list professions
        this._formService.GetProfessioni(this.apiUrl).then((listProf) => {
          this.professionsList = listProf;

          //check if remove profession from config
          if (resp && resp.removeProfession && resp.removeProfession.length > 0) {
            this.professionsList = this.professionsList.filter(xx => resp.removeProfession.indexOf(xx.testo.replace(/\s/g, '').toLowerCase()) == -1);
          }

          //check if is mobile version
          if (window.screen.width < 768) {
            this.professionsList = this.professionsList.filter(xx => xx.isMobileVisible);
          }
        });

        //Set id and name campagna
        if (resp.typeCampagna && resp.typeCampagna === "byUrl") {

          //Get id and name campagna from querystring
          if (resp.infoCampagna && resp.infoCampagna.length > 0) {
            this._params[resp.infoCampagna[0]] ? this.landing.idCampagna = this._params[resp.infoCampagna[0]] : "";
            this._params[resp.infoCampagna[1]] ? this.landing.nomeCampagna = this._params[resp.infoCampagna[1]] : "";
          }

          else {
            //Get id and name campagna from config
            resp.idCampagna ? this.landing.idCampagna = resp.idCampagna : "";
            resp.nomeCampagna ? this.landing.nomeCampagna = resp.nomeCampagna : "";
          }
        }
        else {
          //Get id and name campagna from config
          resp.idCampagna ? this.landing.idCampagna = resp.idCampagna : "";
          resp.nomeCampagna ? this.landing.nomeCampagna = resp.nomeCampagna : "";
        }

        //set google fields
        if (resp.googleParams && resp.googleParamsList.length > 0) {
          resp.googleParamsList.forEach((p) => {
            this._paramsOriginal[p] ? this.landing[p] = this._paramsOriginal[p] : "";
          });
        }

        //Get querystring for ty page
        if (resp.querystring && resp.querystring.length > 0) {
          var i = 0;
          this._qs = "";
          resp.querystring.forEach(() => {
            i === 0 ? this._qs = "?" + resp.querystring[i] + "=" + this._params[resp.querystring[i]] : this._qs += "&" + resp.querystring[i] + "=" + this._params[resp.querystring[i]];
            this._qsList.push(<QueryStringVM>{ key: resp.querystring[i], value: this._params[resp.querystring[i]] });
            i++;
          })
        }
        //get ip client if getIp is true
        if (this.getIp) {
          this._formService.getIpAddress().then((resp) => {
            this.landing.indirizzoIP = resp.ip;
          });
        }

      });
    }
  }


  changeStep(direction: number) {
    this.errorStep1 = false;
    this.errorStep2 = false;
    let valideForm: boolean = true;
    //validate step
    switch (this.actualStep) {
      case 1:
        //check step 1
        if (!this.landing.importo || this.landing.importo <= 0) {
          this.errorStep1 = true;
          valideForm = false;
        }
        if (this.config.idPkProfessione > 0) {
          this.actualStep = 2;
          this.landing.idProfessione = this.config.idPkProfessione;
        }
        break;
      case 2:
        //check step 2
        if (this.landing.idProfessione <= 0 && direction === 1) {
          this.errorStep2 = true;
          valideForm = false;
        }
        break;
    }
    //check if step is ok or back
    if (valideForm || direction === -1) this.actualStep = this.actualStep + direction;
  }


  saveLanding() {

    if (this.myForm.invalid) return;
    this.isSending = true;

    if (this.landing.privacy_terzi) {
      this.landing.privacy_sms = true;
      this.landing.privacy_email = true;
      this.landing.privacy_telefono = true;
    }

    //check if prefix is correct
    this._formService.CheckPrefisso(this.apiUrl, this.landing.numero).then((resp) => {
      switch (resp) {
        case 0:
          //prefix is incorrect
          this.errorPrefisso = true;
          return;
        case 1:
          //prefix is telephone
          this.landing.telefono = this.landing.numero
          break;
        case 2:
          //prefix is mobile
          this.landing.cellulare = this.landing.numero
          break;
        default:
          break;
      }
      this.landing.importo = +this._purifyAmount(this.landing.importo.toString());
      //save landing
      this._formService.SaveLanding(this.apiUrl, this.landing).then((resp) => {
        this._response.success = true;
        this._response.queryString = this._qs;
        this._response.queryStringList = this._qsList;
        this._response.uniqueId = resp.data.toString();

        //set amount profession
        this._formService.GetAmount(this.apiUrl, this.landing.idProfessione).then((resp) => {
          this._response.amountProfessione = resp;
          this.isSending = false;
          //emit output
          this.landingSaved.emit(this._response);
        });
      }).catch((err) => {
        this._response.success = false;
        err && err.status ? this._response.outcome.statusCode = err.status : this._response.outcome.statusCode = "";
        err && err.error && err.error.message ? this._response.outcome.statusMessage = err.error.message : this._response.outcome.statusMessage = "";
        this.isSending = false;
        //emit output
        this.landingSaved.emit(this._response);
      });
    });
  }

  private KeysToLower(source: Object) {
    return Object.keys(source)
      .reduce((destination, key) => {
        destination[key.toLowerCase()] = source[key];
        return destination;
      }, {});
  }

  private _purifyAmount(amt: string): string {
    amt = amt.replace(" €", "");
    amt = amt.replace(".", "");
    amt = amt.toString().replace(",", ".");
    return amt;
  }

}
