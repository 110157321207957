import { MatRadioModule, MatInputModule, MatFormFieldModule, MatCheckboxModule } from '@angular/material';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSitoComponent } from './form-sito.component';
import { OnlyNumber } from './directives/only-number.directive';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { CharValidator } from './directives/Char-validator.directive';
import { DateValidator } from './directives/Date-validator.directive';
import { TelValidator } from './directives/Tel-validator.directive';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatInputModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCheckboxModule,
    TextMaskModule
  ],
  declarations: [
    FormSitoComponent,
    OnlyNumber,
    TelValidator,
    DateValidator,
    CharValidator
  ],
  providers: [],
  exports: [FormSitoComponent]
})
export class FormSitoModule { }
