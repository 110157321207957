import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  activeItem: number = 0;
  isOk: boolean = false;

  constructor(router: Router) {
    router.events.subscribe((val: NavigationStart) => {
      if (val.url && val.url.toLowerCase().includes('ok')) {
        this.isOk = true;
      } else {
        this.isOk = false;
      }
    });
  }

  ngOnInit() {
  }

  setActive(item: number) {
    this.activeItem = item;
  }

}
