import { EntityBase, IEntityBase } from './EntityBase';

export interface ILandingDTO extends IEntityBase {
  idCampagna: string;
  nomeCampagna: string;
  nome: string;
  cognome: string;
  numero: string;
  telefono: string;
  cellulare: string;
  email: string;
  idProvincia: string;
  idProfessione: number;
  importo: number;
  privacy: boolean;
  privacy_terzi: boolean;
  privacy_sms: boolean;
  privacy_email: boolean;
  privacy_telefono: boolean;
  uniqueId: string;
  annoNascita: string;
  dataInserimento: Date;
  indirizzoIP: string;
  companyName: string;
}

export class LandingDTO extends EntityBase implements ILandingDTO {
  constructor(values: Object = {}) {
    super();
    Object.assign(this, values);
  }

  public idCampagna: string;
  public nomeCampagna: string;
  public nome: string;
  public cognome: string;
  public numero: string;
  public telefono: string;
  public cellulare: string;
  public email: string;
  public idProvincia: string;
  public idProfessione: number = 0;
  public importo: number;
  public privacy: boolean=true;
  public privacy_terzi: boolean=false;
  public privacy_sms: boolean=false;
  public privacy_email: boolean=false;
  public privacy_telefono: boolean=false;
  public uniqueId: string;
  public annoNascita: string;
  public dataInserimento: Date;
  public indirizzoIP: string;
  public companyName: string='IlTuoPrestito'
}
