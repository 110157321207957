import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LandingDTO } from '../models/LandingDTO';
import { IntRes } from '../models/IntResVM';

@Injectable()
export class FormService {

  constructor(private http: HttpClient) { }

    /**
   * Save landing
   *
   * @param {string} apiUrl
   * @param {LandingDTO} landing
   * @returns {Promise<IntRes>}
   * @memberof FormService
   */
  public async SaveLanding(apiUrl: string,landing: LandingDTO): Promise<IntRes> {
    return this.http.post<IntRes>(apiUrl + `Landing`, landing).toPromise();
  }
}
