import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthHeader(request));
  }

  addAuthHeader(request: HttpRequest<any>) {
    return request && request.url && request.url.includes('.json')
      ? request.clone()
      : request.clone({
          setHeaders: {
            'sk-site': 'pCDv8^dB77o!RA',
          },
        });
  }
}
