import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OkComponent } from './ok/ok.component';
import { FormComponent } from './form/form.component';

export const routes: Routes = [
  {
    path:'',
    component: FormComponent
  },
  {
    path: 'ok',
    component: OkComponent,
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
