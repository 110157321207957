import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) { }

  public CheckPrefisso(apiUrlPrefix: string, prefisso: string): Observable<{ [data: string]: number }> {
    return this.http.get<{ [data: string]: number }>(apiUrlPrefix + `v2/Prefissi/${prefisso}`);
  }

  public async CheckPrefissoAsync(apiUrlPrefix: string, prefisso: string): Promise<{ [data: string]: number }> {
    return this.http.get<{ [data: string]: number }>(apiUrlPrefix + `v2/Prefissi/${prefisso}`).toPromise();
  }

}
