
export interface IEntityBase {
  id: string;
  edit : boolean;
}

export class EntityBase implements IEntityBase {
  public id: string;
  public edit : boolean;
  public totalCount: number = 0;
  public total: number = 0;
}
