import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigVM } from '../models/ConfigVM';

@Injectable()
export class OkService {

  constructor(private http: HttpClient) { }

  /**
   * Get config json
   *
   * @param {string} pathConfig
   * @returns {Observable<any>}
   * @memberof OkService
   */
  public getConfig(pathConfig: string): Promise<ConfigVM> {
    return this.http.get<ConfigVM>(pathConfig).toPromise();
  }

}
