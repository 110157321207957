import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormSitoModule } from "./component/form-sito.module";

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

import { AppComponent } from "./app.component";
import { OkComponent } from "./ok/ok.component";
import { AppRoutingModule } from "./app.routing";
import { FormComponent } from "./form/form.component";
import { TopBarComponent } from "./top-bar/top-bar.component";
import { OkService } from "./services/ok.service";
import { FormService } from "./services/form.service";
import { AuthInterceptor } from "./services/auth-interceptor.service";

@NgModule({
  declarations: [AppComponent, OkComponent, FormComponent, TopBarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormSitoModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
  ],
  providers: [
    OkService,
    FormService,
    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
