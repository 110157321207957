import { IEntityBase, EntityBase } from './EntityBase';
export interface IIpInfoVM extends IEntityBase {
    ip: string;
    country_code: string;
    country_name: string;
    region_code: string;
    region_name: string;
    city: string;
    zip_code: string;
    time_zone: string;
    latitude: string;
    longitude: string;
    metro_code: number;
}

export class IpInfoVM extends EntityBase implements IIpInfoVM {
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
    }

    public ip: string;
    public country_code: string;
    public country_name: string;
    public region_code: string;
    public region_name: string;
    public city: string;
    public zip_code: string;
    public time_zone: string;
    public latitude: string;
    public longitude: string;
    public metro_code: number;
}