import { Directive, forwardRef } from '@angular/core';
import { NG_ASYNC_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[DateValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => DateValidator), multi: true,
    }
  ]
})
export class DateValidator implements Validator {
  constructor() { }

  validate(c: AbstractControl): { [key: string]: any } {

    let v: number = c.value;

    let now = new Date;
    let diff = now.getFullYear() - v;

    return new Promise(resolve => {
      if (diff > 17 && diff < 100)
        resolve(null)
      else
        resolve({ 'invalidDate': true })
    });
  }

}
